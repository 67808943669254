import React, { useState } from "react"
import classNames from "classnames"
import moment from "moment"
import StylisedText from "../elements/stylised-text"
import ButtonLink from "../elements/button-link"

const CardBackground = ({ image, colour, hover }) => {
  return (
    <>
      <div
        className={classNames(
          // Common classes
          "card__overlay",
          colour,
          {"card__overlay--fade": hover}
        )}
      ></div>
      <div className="card__background">
        {image.ext === ".mp4" || image.ext === ".webm" ? (
          <div className="videoWrapper">
            <video
              className="video"
              preload
              loop
              autoPlay
              muted
              playsinline="true"
            >
              {image.ext === ".mp4" ? (
                <source src={image.url} type="video/mp4" />
              ) : (
                ""
              )}
              {image.ext === ".webm" ? (
                <source src={image.url} type="video/webm" />
              ) : (
                ""
              )}
            </video>
          </div>
        ) : (
          <div
            className="card__background"
            style={{ backgroundImage: `url(${image.url})` }}
          ></div>
        )}
      </div>
    </>
  )
}

const Card = ({ data }) => {

  const [hover, setHover] = useState(false)

  return (
    <div
      className="card__outer card__outer--regular"
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      role="button"
      tabIndex={0}
    >
      <div
        className={classNames(
          // Common classes
          "card",
          "card--regular",
          data.colour,
          data.size
        )}
      >
        {data.background !== null ? (
          <CardBackground image={data.background} colour={data.colour} hover={hover} />
        ) : null}
        <div className="card__content">
          <StylisedText data={data.title} type="h3" />
          <p className="regular">{data.description}</p>
          <div className="card__content__button">
            {data.link && (
              <ButtonLink button={data.link} appearance={data.link.type} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const InsightCard = ({ data, category }) => {
  return (
    <article className="card__outer card__outer--insight">
      <div
        className={classNames(
          // Common classes
          "card",
          "card--insight"
        )}
      >
        {data.cardBackground !== null ? (
          <CardBackground image={data.cardBackground} colour="black" />
        ) : null}
        <div className="card__content">
          <div>
            <span className="card__date">
              {moment(data.publishDate).format("DD.MM.yyyy")}
            </span>
            <br />
            <span className="card__title">{data.title}</span>
          </div>
          <ButtonLink
            button={{
              url: category
                ? `/insights/${category}/${data.slug}`
                : `/insights/${data.slug}`,
              newTab: false,
              text: "Read More",
              type: "primary",
            }}
            appearance="primary"
          />
        </div>
      </div>
    </article>
  )
}

const CareerCard = ({ data }) => {
  return (
    <article className="card__outer card__outer--career">
      <div
        className={classNames(
          // Common classes
          "card",
          "card--career"
        )}
      >
        {data.cardBackground !== null ? (
          <CardBackground image={data.cardBackground} colour="black" />
        ) : null}
        <div className="card__content">
          <div>
            <span className="card__date">
              {moment(data.publishDate).format("DD.MM.yyyy")}
            </span>
            <br />
            <span className="card__title">{data.title}</span>
          </div>
          <ButtonLink
            button={{
              url: `/careers/${data.slug}`,
              newTab: false,
              text: "See Job",
              type: "primary",
            }}
            appearance="primary"
          />
        </div>
      </div>
    </article>
  )
}

export { Card, InsightCard, CareerCard }
