import React from "react"
import classNames from "classnames";
import emojione from 'emojione';
import PropTypes from "prop-types"
import { breadcrumbTitlePropTypes } from "@/utils/types"

const BreadcrumbTitle = ({ data }) => {

    const titleEmojified = emojione.shortnameToUnicode(data.title);

    if (data.dashColour == null)
        data.dashColour = "orange";
    return (
        <div className="breadcrumb-title">
            <span className={classNames(
            "dash",
            `${data.dashColour}`
        )}>---/ </span><span className={data.colour}>{titleEmojified}</span>
        </div>
    )
}

BreadcrumbTitle.propTypes = {
    data: PropTypes.oneOfType([
        breadcrumbTitlePropTypes,
    ]).isRequired
}

export default BreadcrumbTitle
